
import { defineComponent } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import WidgetsEmpty from '@/components/pages/chats/settings/channels/web-widgets/WidgetsEmpty.vue'

export default defineComponent({
  components: {
    WidgetsEmpty,
    DetailsHero,
    ChatsSettingsPage,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Web widgets' },
    ]

    return {
      breadcrumbs,
    }
  },
})
